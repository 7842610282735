body {
	margin: 0;
	font-family: 'Cairo', sans-serif;
	min-height: 100%;
	height: 100vh;
}

#root {
	height: 100%;
	min-height: 100%;
}

.footer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20;
	justify-content: center;
}

.footer > div {
    flex: 1 1 30%; /* Flex-grow, flex-shrink, and flex-basis */
    margin: 0px 50px; /* Adjust as needed for spacing */
    min-width: 250px; /* Set a minimum width for each column */
}
